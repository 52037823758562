











































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'rodo',
  metaInfo: {
    title: 'Informacja o przetwarzaniu danych osobowych',
  },
  data() {
    return {
      lang: this.$route.query.lang || 'pl',
    };
  },
  watch: {
    '$route.query.lang'(lang) {
      this.lang = lang;
    },
  },
});
